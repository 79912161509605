<template>
  <div>
    <div class="mt-5 mx-5 mb-3">
      <b-form-input
        :class="(formInvalid) ? 'is-invalid' : ''"
        v-model="name"
        @input="setName"
        @keyup.enter="$emit('enter-pressed');"
        autofocus
        debounce="500"
      >
      </b-form-input>
      <div
        v-if="formInvalid"
        class="invalid-feedback"
      >
        {{ $t('webmag.insert_name') }}
      </div>
    </div>
    <div class="preview text-center mb-3"><small>{{ computedUrl }}</small></div>
    <p class="text-center mx-5 mb-4 px-3 pb-2">
      {{ $t('webmag.folder_newName') }}
    </p>
  </div>
</template>

<script>
import slugify from '@sindresorhus/slugify';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'ModalChooseNameContent',
  mixins: [validationMixin],
  props: {
    groupId: {
      type: Number,
      default: null,
    },
    groupSlug: {
      type: String,
      required: true,
    },
    childSlugs: {
      type: Array,
      required: true,
    },
    itemName: {
      type: String,
      required: false,
      default: null,
    },
    itemType: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    name: null,
  }),
  created() {
    this.name = this.itemName;
  },
  validations: {
    name: {
      required,
    },
  },
  computed: {
    formInvalid() {
      if (this.$v.name.$anyDirty && !this.name) {
        return true;
      }
      if (this.$v.name.$anyDirty && this.$v.name.$anyError) {
        return true;
      }
      return false;
    },
    computedSlugOfName() {
      if (this.name) {
        const itemSlugOriginal = slugify(this.name);

        // check if slug already exists
        let slugCount = 0; // initial count
        let itemSlug = null;
        let itemWithSameSlug = null;

        while (itemWithSameSlug !== undefined) {
          // at the first time we have to check if the original slug exists
          itemSlug = (slugCount === 0)
            ? itemSlugOriginal
            : `${itemSlugOriginal}-${slugCount}`;

          itemWithSameSlug = this.childSlugs.find(
            // eslint-disable-next-line no-loop-func
            (slug) => slug === itemSlug,
          );
          slugCount++;
        }
        this.$emit('update:itemSlug', itemSlug);
        // add a slash at the end for display purpose
        if (this.itemType === 'folder' || this.itemType === 'project') {
          itemSlug = `${itemSlug}/`;
        }
        return itemSlug;
      }
      this.$emit('update:itemSlug', '');
      return '';
    },
    computedUrl() {
      // replace [group_slug] with group slug
      const groupPreviewDomain = process.env.VUE_APP_DOMAIN.replace('[group_slug]', this.groupSlug);
      const parentUrlPath = (this.$route.params.parametersOfUrl)
        ? `${this.$route.params.parametersOfUrl}/`
        : '';
      return (this.computedSlugOfName)
        ? `${groupPreviewDomain}/${parentUrlPath}${this.computedSlugOfName}`
        : `${groupPreviewDomain}/${parentUrlPath}`;
    },
  },
  destroyed() {
    this.$emit('update:itemName', null);
  },
  methods: {
    setName(value) {
      this.name = value;
      this.$v.name.$touch();
      this.$emit('update:itemName', value);
    },
  },
};
</script>

<style lang="scss" scoped>
small {
  color: #35393e;
}
</style>
