var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "mt-5 mx-5 mb-3" },
      [
        _c("b-form-input", {
          class: _vm.formInvalid ? "is-invalid" : "",
          attrs: { autofocus: "", debounce: "500" },
          on: {
            input: _vm.setName,
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.$emit("enter-pressed")
            }
          },
          model: {
            value: _vm.name,
            callback: function($$v) {
              _vm.name = $$v
            },
            expression: "name"
          }
        }),
        _vm.formInvalid
          ? _c("div", { staticClass: "invalid-feedback" }, [
              _vm._v(" " + _vm._s(_vm.$t("webmag.insert_name")) + " ")
            ])
          : _vm._e()
      ],
      1
    ),
    _c("div", { staticClass: "preview text-center mb-3" }, [
      _c("small", [_vm._v(_vm._s(_vm.computedUrl))])
    ]),
    _c("p", { staticClass: "text-center mx-5 mb-4 px-3 pb-2" }, [
      _vm._v(" " + _vm._s(_vm.$t("webmag.folder_newName")) + " ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }